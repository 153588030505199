import React, { useEffect, useState } from "react";
import { Col, Input, Row, Table, Tooltip } from "antd";
import HeadingBack from "../component/HeadingBack";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import SvgIcon from "../const/SvgIcon";
import { allapiAction } from "../../Redux/common/action";
import moment from "moment";
import ImageUploading from "react-images-uploading";
import { Modal } from "antd";
import { toast } from "react-toastify";
import { AsyncPaginate } from "react-select-async-paginate";
import { URL } from "../../Redux/common/url";

function LeavelAchiveIncome() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pagev = searchParams.get("page");
  const searchData = searchParams.get("search");
  const statusData2 = searchParams.get("status");

  const page = pagev == null ? 1 : pagev;
  const search = searchData == null ? "" : searchData;
  const statusfinal = statusData2 == null ? "User" : "User";

  const users_list = useSelector((state) =>
    state?.allapi?.gte_level_income_list
      ? state?.allapi?.gte_level_income_list
      : {}
  );
  const get_seller_profile = useSelector((state) =>
    state?.allapi?.get_seller_profile ? state?.allapi?.get_seller_profile : {}
  );

  const [userDetailsEdit, setUserDetailsEdit] = useState({});
  const [userKycEdit, setUserKycEdit] = useState({});
  const [images, setImages] = useState([]);
  const [aadharFront, setaadharFront] = useState([]);
  const [aadharBack, setaadharBack] = useState([]);
  const maxNumber = 1;

  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
  };
  const onChangeaadharFront = (imageList, addUpdateIndex) => {
    setaadharFront(imageList);
  };
  const onChangeaadharBack = (imageList, addUpdateIndex) => {
    setaadharBack(imageList);
  };

  const hendleToprofileEdit = (e) => {
    const { name, value } = e?.target;

    setUserDetailsEdit({ ...userDetailsEdit, [name]: value });
  };
  const hendleToprofileEditKyc = (e) => {
    const { name, value } = e?.target;

    setUserKycEdit({ ...userKycEdit, [name]: value });
  };
  useEffect(() => {
    setUserDetailsEdit({
      _id: get_seller_profile?._id,
      fullName: get_seller_profile?.fullName
        ? get_seller_profile?.fullName
        : "",
      guardian: get_seller_profile?.guardian
        ? get_seller_profile?.guardian
        : "",
      father_name: get_seller_profile?.father_name
        ? get_seller_profile?.father_name
        : "",
      email: get_seller_profile?.email ? get_seller_profile?.email : "",
      phone: get_seller_profile?.phone ? get_seller_profile?.phone : "",
      gender: get_seller_profile?.gender ? get_seller_profile?.gender : "",
      city: get_seller_profile?.city ? get_seller_profile?.city : "",
      state: get_seller_profile?.state ? get_seller_profile?.state : "",
      country: get_seller_profile?.country ? get_seller_profile?.country : "",
      zip: get_seller_profile?.zip ? get_seller_profile?.zip : "",
      marital: get_seller_profile?.marital ? get_seller_profile?.marital : "",
      professional: get_seller_profile?.professional
        ? get_seller_profile?.professional
        : "",
      address1: get_seller_profile?.address1
        ? get_seller_profile?.address1
        : "",
      address2: get_seller_profile?.address2
        ? get_seller_profile?.address2
        : "",
    });

    setUserKycEdit({
      pan_card: get_seller_profile?.kyc_id?.pan_card
        ? get_seller_profile?.kyc_id?.pan_card
        : "",
      aadhar_card: get_seller_profile?.kyc_id?.aadhar_card
        ? get_seller_profile?.kyc_id?.aadhar_card
        : "",
      ifsc: get_seller_profile?.kyc_id?.ifsc
        ? get_seller_profile?.kyc_id?.ifsc
        : "",
      acc_no: get_seller_profile?.kyc_id?.acc_no
        ? get_seller_profile?.kyc_id?.acc_no
        : "",
      _id: get_seller_profile?.kyc_id?._id,
    });

    if (get_seller_profile?.kyc_id?.pancardImage) {
      setImages([
        {
          data_url: URL.API_BASE_URL + get_seller_profile?.kyc_id?.pancardImage,
        },
      ]);
    }
    if (get_seller_profile?.kyc_id?.aadharfrontImage) {
      setaadharFront([
        {
          data_url:
            URL.API_BASE_URL + get_seller_profile?.kyc_id?.aadharfrontImage,
        },
      ]);
    }
    if (get_seller_profile?.kyc_id?.aadharbacktImage) {
      setaadharBack([
        {
          data_url:
            URL.API_BASE_URL + get_seller_profile?.kyc_id?.aadharbacktImage,
        },
      ]);
    }
  }, [get_seller_profile]);

  const [pages, setPage] = useState(1);

  const listdata = users_list?.docs ? users_list?.docs : [];

  useEffect(() => {
    // searchUserListStatus
    // getSellerListStatus

    getuserList();

    return () => {};
  }, [location]);

  const getuserList = () => {
    if (search) {
      if (statusfinal) {
        dispatch(
          allapiAction.getlavelAchiveincomeAdmin({
            page: page,
            key: search,
            status: statusfinal,
          })
        );
      } else {
        dispatch(
          allapiAction.getlavelAchiveincomeAdmin({ page: page, key: search })
        );
      }
    } else {
      if (statusfinal) {
        dispatch(
          allapiAction.getlavelAchiveincomeAdmin({
            page: page,
            status: statusfinal,
          })
        );
      } else {
        // dispatch(allapiAction.getUserListseacrch({ page: page, key: search }));
        dispatch(allapiAction.getlavelAchiveincomeAdmin({ page }));
      }
    }
  };

  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userId, setuserId] = useState("");

  const [statusData, setstatusData] = useState({});
  const [userData, setuserData] = useState({});

  const [statusDataBox, setstatusDataBox] = useState(false);
  const [statusUser, setstatusUser] = useState(false);
  const [statusLicence, setstatusLicence] = useState(false);
  const [statusEdit, setstatusEdit] = useState(false);
  const [statusEditKyc, setstatusEditKyc] = useState(false);
  const [statusKycAp, setstatusEditAp] = useState(false);

  const [kycStatus, setuserkycStatus] = useState({});
  console.log(kycStatus);

  const hendleTochangekyc = (e) => {
    const { name, value } = e.target;

    setuserkycStatus({ ...kycStatus, [name]: value });
  };

  const hendleToHideKyc = () => {
    setstatusEditKyc(false);
  };
  const hendleToViewM = (e) => {
    setstatusDataBox(true);
    setuserData(e);
  };
  const hendleToViewUser = (e) => {
    setstatusUser(true);
    setuserData(e);
    dispatch(allapiAction.getsellerprofile({ id: e?._id }));
  };
  const hendleToViewLicence = (e) => {
    setstatusLicence(true);
    setuserData(e);
    dispatch(allapiAction.getsellerprofile({ id: e?._id }));
  };
  const hendleToKycStatus = (e) => {
    setstatusEditAp(true);
    setuserkycStatus(e);
    // dispatch(allapiAction.getsellerprofile({ id: e?._id }));
  };
  const hendleToViewEdit = (e) => {
    setstatusEdit(true);
    setuserData(e);
    dispatch(allapiAction.getsellerprofile({ id: e?._id }));
  };
  const hendleToViewEditKyc = (e) => {
    setstatusEditKyc(true);
    setuserData(e);
    dispatch(allapiAction.getsellerprofile({ id: e?._id }));
  };

  const hendleTochangedata = (e) => {
    const { name, value } = e.target;

    setstatusData({ ...statusData, [name]: value });
  };

  const edituserKycByadmin = async () => {
    // edituserKycByadmin
    const data = new FormData();

    if (images[0]?.file) {
      // roledata?.role_name
      data.append("pancardImage", images[0]?.file);
    }
    if (aadharFront[0]?.file) {
      // roledata?.role_name
      data.append("aadharfrontImage", aadharFront[0]?.file);
    }
    if (aadharBack[0]?.file) {
      // roledata?.role_name
      data.append("aadharbacktImage", aadharBack[0]?.file);
    }
    data.append("_id", userKycEdit?._id);
    data.append("acc_no", userKycEdit?.acc_no);
    data.append("ifsc", userKycEdit?.ifsc);
    data.append("aadhar_card", userKycEdit?.aadhar_card);
    data.append("pan_card", userKycEdit?.pan_card);

    const response = await dispatch(allapiAction.edituserKycByadmin(data));

    if (response?.success) {
      //   getuserList();
      setstatusEditKyc(false);
      setaadharBack([]);
      setaadharFront([]);
      setImages([]);
    }
  };

  const edituserProfileByadmin = async () => {
    const response = await dispatch(
      allapiAction.edituserProfileByadmin({
        _id: userDetailsEdit?._id,
        fullName: userDetailsEdit?.fullName,
        guardian: userDetailsEdit?.guardian,
        father_name: userDetailsEdit?.father_name,
        email: userDetailsEdit?.email,
        phone: userDetailsEdit?.phone,
        gender: userDetailsEdit?.gender,
        city: userDetailsEdit?.city,
        state: userDetailsEdit?.state,
        country: userDetailsEdit?.country,
        zip: userDetailsEdit?.zip,
        marital: userDetailsEdit?.marital,
        professional: userDetailsEdit?.professional,
        address1: userDetailsEdit?.address1,
        address2: userDetailsEdit?.address2,
      })
    );

    if (response?.success) {
      //   getuserList();
      setstatusEdit(false);
    }
  };

  const hendleToKycSubmetUpdate = () => {
    dispatch(
      allapiAction.KycstatusUpdateByAdmin({
        _id: kycStatus?._id,
        kycStatus: kycStatus?.kycStatus,

        pageNumber: page,
      })
    );

    setstatusEditAp(false);
  };
  const hendleToSubmetUpdate = async () => {
    const response = await dispatch(
      allapiAction.addFoundByAdmin({
        user_id: userData?._id,
        amount: statusData?.amount,

        pageNumber: page,
      })
    );

    if (response?.success) {
      getuserList();
      setstatusDataBox(false);
    }
  };

  const showModal = (e) => {
    console.log(e);
    setuserId(e);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    dispatch(
      allapiAction.deleteUserByadmin({
        _id: userId,
        status: userId,
        // active: 0,
        pageNumber: page,
      })
    );
    setuserId("");
    setIsModalOpen(false);
    return () => {};
  };

  const hendleToChangeStatus = async (id, status) => {
    const response = await dispatch(
      allapiAction.user_inactive({
        _id: id,
        status: status,
        // active: 0,
        pageNumber: page,
      })
    );

    if (response?.success) {
      getuserList();
    }

    // setuserId("");
    // setIsModalOpen(false);
    return () => {};
  };
  const hendleToChangeStatusarea = async (id, status) => {
    const response = await dispatch(
      allapiAction.getUserListActivedistributor({
        _id: id,
        status: status,
        type: "area",
        // active: 0,
        pageNumber: page,
      })
    );

    if (response?.success) {
      getuserList();
    }

    // setuserId("");
    // setIsModalOpen(false);
    return () => {};
  };
  const hendleToChangeStatusdistrict = async (id, status) => {
    const response = await dispatch(
      allapiAction.getUserListActivedistributor({
        _id: id,
        status: status,
        type: "district",
        // active: 0,
        pageNumber: page,
      })
    );

    if (response?.success) {
      getuserList();
    }

    // setuserId("");
    // setIsModalOpen(false);
    return () => {};
  };
  const hendleToChangeStatusstate = async (id, status) => {
    const response = await dispatch(
      allapiAction.getUserListActivedistributor({
        _id: id,
        status: status,
        type: "state",
        // active: 0,
        pageNumber: page,
      })
    );

    if (response?.success) {
      getuserList();
    }

    // setuserId("");
    // setIsModalOpen(false);
    return () => {};
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [lisshowcount, setlisscount] = useState("");
  const [managerKey, setManagerKey] = useState(0);
  const [userselete, setuserselete] = useState(null);

  const loadOptionsPalyer = async (search, loadedOptions, page) => {
    const response = await fetch(
      URL.API_BASE_URL +
        `/publicApi/getuserforlinsense?search=${search}&page=${page}&user_id=${get_seller_profile?.referId}`
    );
    const responseJSON = await response.json();

    const options = responseJSON?.results?.map((data) => {
      data.value = data?.id;
      data.label = data?.fullName;

      return data;
    });

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: page + 1,
      },
    };
  };
  const hendletoAssLicence = () => {
    // addlicence

    const userLicense =
      lisshowcount == 1
        ? get_seller_profile?.Userchaindata?.license1
        : lisshowcount == 2
        ? get_seller_profile?.Userchaindata?.license2
        : lisshowcount == 3
        ? get_seller_profile?.Userchaindata?.license3
        : get_seller_profile?.Userchaindata?.license4;

    if (!lisshowcount) {
      toast.error("please select license");
    } else if (!userLicense) {
      toast.error("already used");
    } else {
      dispatch(
        allapiAction.addlicence({
          userIdFrom: get_seller_profile?._id,
          userIdTo: userselete?._id,
          licence: userLicense,
          licenceno: lisshowcount,
        })
      );
      setstatusLicence(false);
    }
  };

  const columns = [
    {
      title: "S.No.",
      dataIndex: "sno",
      key: "sno",
      className: "table_first_col",
      render: (value, item, index) => (page - 1) * 10 + index + 1,
    },
    {
      title: "User Id",
      dataIndex: "referId",
      key: "referId",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            <span
              className="cursor-pointer coustomNevc"
              // onClick={() => navigate(`/user-details/${item?.referId}`)}
            >
              {item?.sp?.referId}
            </span>
          </>
        );
      },
    },
    {
      title: "User Name",
      dataIndex: "fullName",
      key: "fullName",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            <span
              className="cursor-pointer coustomNevc"
              // onClick={() => navigate(`/user-details/${item?.referId}`)}
            >
              {item?.sp?.fullName}
            </span>
          </>
        );
      },
    },
    {
      title: "Number",
      dataIndex: "phone",
      key: "phone",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            <span
              className="cursor-pointer coustomNevc"
              // onClick={() => navigate(`/user-details/${item?.referId}`)}
            >
              {item?.sp?.phone}
            </span>
          </>
        );
      },
    },
    {
      title: "Sponsor Id",
      dataIndex: "referBy",
      key: "referBy",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            <span
              className="cursor-pointer coustomNevc"
              // onClick={() => navigate(`/user-details/${item?.referId}`)}
            >
              {item?.sp?.referBy}
            </span>
          </>
        );
      },
    },
    {
      title: "Level No ",
      dataIndex: "referBy",
      key: "referBy",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            <span
              className="cursor-pointer coustomNevc"
              // onClick={() => navigate(`/user-details/${item?.referId}`)}
            >
              {item?.levelNo}
            </span>
          </>
        );
      },
    },
    {
      title: "Income",
      dataIndex: "Income",
      key: "Income",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            <span
              className={
                item?.sp?.account_status == "Inactive"
                  ? "cursor-pointer coustomfalse"
                  : item?.userdisshow == 1
                  ? "cursor-pointer coustomtrue2"
                  : "cursor-pointer coustomBlack"
              }
              // onClick={() => navigate(`/user-details/${item?.referId}`)}
            >
              {item?.commision}
            </span>
          </>
        );
      },
    },
    // {
    //   title: "Income",
    //   dataIndex: "commision",
    //   key: "commision",
    // },

    {
      title: "Date",
      dataIndex: "Join Date",
      key: "Join Date",
      // className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer"
              // onClick={() => navigate(`/level-income/contact/${item?.id}`)}
            >
              {moment(item?.donationdate).format(" DD/MM/YYYY ")}
            </span>
          </>
        );
      },
    },

    // {
    //   title: "Ekyc",
    //   dataIndex: "kycStatus",
    //   key: "kycStatus",
    //   // className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         {/* <Tooltip title="Contact"> */}

    //         <span
    //           className={
    //             item?.kycStatus == "Approved"
    //               ? "cursor-pointer coustomtrue"
    //               : "cursor-pointer coustomfalse"
    //           }
    //           onClick={() => hendleToKycStatus(item)}
    //           // onClick={() => navigate(`/user-details/${item?.referId}`)}
    //         >
    //           {item?.kycStatus}
    //         </span>
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "Status",
    //   dataIndex: "user_status",
    //   key: "user_status",
    //   // className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         <Tooltip title={item?.user_status}>
    //           <button
    //             className={
    //               item?.user_status == "Active"
    //                 ? "success-button ml-3"
    //                 : "delete-button2 ml-3"
    //             }
    //             onClick={() =>
    //               hendleToChangeStatus(
    //                 item?._id,
    //                 item?.user_status == "Active" ? "Inactive" : "Active"
    //               )
    //             }
    //           >
    //             {item?.user_status}
    //           </button>
    //         </Tooltip>
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "Account Status",
    //   dataIndex: "account_status",
    //   key: "account_status",
    //   // className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         <Tooltip title={item?.account_status}>
    //           <button
    //             className={
    //               item?.account_status == "Active"
    //                 ? "success-button ml-3"
    //                 : "delete-button2 ml-3"
    //             }
    //             // onClick={() =>
    //             //   hendleToChangeStatus(
    //             //     item?._id,
    //             //     item?.account_status == "Active" ? "Inactive" : "Active"
    //             //   )
    //             // }
    //           >
    //             {item?.account_status}
    //           </button>
    //         </Tooltip>
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "Arae Distibutor",
    //   dataIndex: "isaraeDistibutor",
    //   key: "isaraeDistibutor",
    //   // className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         <Tooltip title={item?.isaraeDistibutor}>
    //           <button
    //             className={
    //               item?.isaraeDistibutor == "Active"
    //                 ? "success-button ml-3"
    //                 : "delete-button2 ml-3"
    //             }
    //             onClick={() =>
    //               hendleToChangeStatusarea(
    //                 item?._id,
    //                 item?.isaraeDistibutor == "Active" ? "Inactive" : "Active"
    //               )
    //             }
    //           >
    //             {item?.isaraeDistibutor}
    //           </button>
    //         </Tooltip>
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "District Distibutor",
    //   dataIndex: "isdistrictDistibutor",
    //   key: "isdistrictDistibutor",
    //   // className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         <Tooltip title={item?.isdistrictDistibutor}>
    //           <button
    //             className={
    //               item?.isdistrictDistibutor == "Active"
    //                 ? "success-button ml-3"
    //                 : "delete-button2 ml-3"
    //             }
    //             onClick={() =>
    //               hendleToChangeStatusdistrict(
    //                 item?._id,
    //                 item?.isdistrictDistibutor == "Active"
    //                   ? "Inactive"
    //                   : "Active"
    //               )
    //             }
    //           >
    //             {item?.isdistrictDistibutor}
    //           </button>
    //         </Tooltip>
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "State Distibutor",
    //   dataIndex: "isstateDistibutor",
    //   key: "isstateDistibutor",
    //   // className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         <Tooltip title={item?.isstateDistibutor}>
    //           <button
    //             className={
    //               item?.isstateDistibutor == "Active"
    //                 ? "success-button ml-3"
    //                 : "delete-button2 ml-3"
    //             }
    //             onClick={() =>
    //               hendleToChangeStatusstate(
    //                 item?._id,
    //                 item?.isstateDistibutor == "Active" ? "Inactive" : "Active"
    //               )
    //             }
    //           >
    //             {item?.isstateDistibutor}
    //           </button>
    //         </Tooltip>
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "Student Mobile",
    //   dataIndex: "student_mobile",
    //   key: "student_mobile",
    // },
    // {
    //   title: "Parent Name",
    //   dataIndex: "parent_name",
    //   key: "parent_name",
    // },
    // {
    //   title: "Parent Mobile",
    //   dataIndex: "parent_mobile",
    //   key: "parent_mobile",
    // },
    // {
    //   title: "Address",
    //   dataIndex: "address",
    //   key: "address",
    // },
    // {
    //   title: "Actions",
    //   dataIndex: "action",
    //   key: "action",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         <Tooltip title="View">
    //           <span
    //             className="cursor-pointer"
    //             onClick={() => hendleToViewUser(item)}
    //           >
    //             <SvgIcon.Visibility className="scale-icon edit-icon" />
    //           </span>
    //         </Tooltip>
    //         {/* <Tooltip title="delete">
    //           <span
    //             className="cursor-pointer ml-3"
    //             onClick={() => showModal(item?._id)}
    //           >
    //             <SvgIcon.deletebtn className="scale-icon edit-icon" />

    //           </span>
    //         </Tooltip> */}
    //         <Tooltip title="Edit">
    //           <span
    //             className="cursor-pointer ml-3"
    //             onClick={() => hendleToViewEdit(item)}
    //           >
    //             <SvgIcon.Pencil className="scale-icon edit-icon" />
    //             {/* Edit */}
    //           </span>
    //         </Tooltip>
    //         {/* {item?.userlicence == "1" && (
    //           <Tooltip title="license assign">
    //             <button
    //               className="admin-button ml-3 mt-3"
    //               onClick={() => hendleToViewLicence(item)}
    //             >
    //               License Assign
    //             </button>
    //           </Tooltip>
    //         )} */}

    //         <Tooltip title="Edit Kyc">
    //           <button
    //             className="admin-button ml-3 mt-3"
    //             onClick={() => hendleToViewEditKyc(item)}
    //           >
    //             Edit Kyc
    //           </button>
    //         </Tooltip>

    //         <Tooltip title="Add Point">
    //           <button
    //             className="admin-button ml-3  mt-3"
    //             onClick={() => hendleToViewM(item)}
    //           >
    //             Add Point
    //           </button>
    //         </Tooltip>
    //       </>
    //     );
    //   },
    // },
  ];

  const [searchs, setsearch] = useState("");
  const handleChange = (e) => {
    setsearch(e);
    setPage(1);

    if (statusfinal) {
      navigate(
        "/level-achive-income?page=" + 1 + "&search=" + e + "&status=" + statusfinal
      );
    } else {
      navigate("/level-achive-income?page=" + 1 + "&search=" + e);
    }
    // if (e) {
    //   dispatch(allapiAction.getuserListSearch({ page: 1, search: e }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(1));

    //   return () => {};
    // }
    // getuserListSearch
    // &user_id=${data?.user_id}&search=${data?.user_id}
  };

  const handleChangeStatus = (e) => {
    // setsearch(e);
    setPage(1);
    navigate("/level-achive-income?page=" + 1 + "&search=" + search + "&status=" + e);
    // if (e) {
    //   dispatch(allapiAction.getuserListSearch({ page: 1, search: e }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(1));

    //   return () => {};
    // }
    // getuserListSearch
    // &user_id=${data?.user_id}&search=${data?.user_id}
  };

  const hendlePahegi = (e) => {
    setPage(e);

    console.log(location);

    if (statusfinal) {
      navigate(
        "/level-achive-income?page=" +
          e +
          "&search=" +
          search +
          "&status=" +
          statusfinal
      );
    } else {
      navigate("/level-achive-income?page=" + e + "&search=" + search);
    }

    // if (search) {
    //   dispatch(allapiAction.getuserListSearch({ page: e, search: search }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(e));

    //   return () => {};
    // }
  };

  return (
    <div>
      <Modal
        title="delete User"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Are you sure delete</p>
      </Modal>

      <Modal
        title="Add Amount"
        centered
        open={statusDataBox}
        onOk={() => hendleToSubmetUpdate(false)}
        onCancel={() => setstatusDataBox(false)}
        width={500}
      >
        <div>
          <Row>
            <Col span={24}>Amount</Col>
            <Col span={24}>
              <Input
                name="amount"
                placeholder="enter amount"
                value={statusData?.amount}
                onChange={(e) => {
                  hendleTochangedata(e);
                }}
              />
            </Col>
          </Row>
        </div>
      </Modal>

      {/* kyc status */}
      <Modal
        title="Kyc"
        centered
        open={statusKycAp}
        onOk={() => hendleToKycSubmetUpdate(false)}
        onCancel={() => setstatusEditAp(false)}
        width={500}
      >
        <div>
          <Row>
            <Col span={24}>Kyc</Col>
            <Col span={24}>
              <select
                name="kycStatus"
                className="form-control"
                onChange={(e) => {
                  hendleTochangekyc(e);
                }}
                value={kycStatus?.kycStatus}
              >
                {/* <option>Select </option> */}
                <option value={"Approved"}>Approved</option>
                <option value={"Unapproved"}>Unapproved</option>
                {/* <option value={"W/O"}>W/O</option> */}
              </select>
              {/* <Input
                name="amount"
                placeholder="enter amount"
                value={statusData?.amount}
                onChange={(e) => {
                  hendleTochangedata(e);
                }}
              /> */}
            </Col>
          </Row>
        </div>
      </Modal>

      {/* details  */}
      <Modal
        title="User Details"
        centered
        open={statusUser}
        onOk={() => setstatusUser(false)}
        onCancel={() => setstatusUser(false)}
        width={1000}
      >
        <div>
          <div className="mb-10 d-flex">
            <div className="col-12">
              {/* get_seller_profile */}
              <div className="d-flex">
                <div className="col-3">
                  <h6>Name</h6>
                </div>
                <div className="col-9">
                  {" "}
                  <p>{get_seller_profile?.fullName}</p>
                </div>
              </div>
              <div className="d-flex">
                <div className="col-3">
                  <h6>Email</h6>
                </div>
                <div className="col-9">
                  {" "}
                  <p>{get_seller_profile?.email}</p>
                </div>
              </div>
              <div className="d-flex">
                <div className="col-3">
                  <h6>Role</h6>
                </div>
                <div className="col-9">
                  {" "}
                  <p>{get_seller_profile?.role_name}</p>
                </div>
              </div>
              <div className="d-flex">
                <div className="col-3">
                  <h6>Balance</h6>
                </div>
                <div className="col-9">
                  {" "}
                  <p>{get_seller_profile?.balance}</p>
                </div>
              </div>
              <div className="d-flex">
                <div className="col-3">
                  <h6>User Id</h6>
                </div>
                <div className="col-9">
                  {" "}
                  <p>{get_seller_profile?.referId}</p>
                </div>
              </div>
              <div className="d-flex">
                <div className="col-3">
                  <h6>Upline Id</h6>
                </div>
                <div className="col-9">
                  {" "}
                  <p>{get_seller_profile?.referBy}</p>
                </div>
              </div>

              {/* <h6>Email</h6>
                      <p>{get_seller_profile?.email}</p>
                      <h6>Role</h6>
                      <p>{get_seller_profile?.role_name}</p> */}
              {/* <h3>Rank </h3> */}

              {/* <div className="d-flex mb-5">
                
                <div className="col-8">
                  <div className="d-flex">
                    <div className="col-3">
                      <h6>fr date</h6>
                    </div>
                    <div className="col-9">
                      {" "}
                      <p>
                        {" "}
                        {get_seller_profile?.Userchaindata?.fr_date &&
                          moment(
                            get_seller_profile?.Userchaindata?.fr_date
                          ).format("MMM DD, yyyy hh:mm:ss A")}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="col-3">
                      <h6>br date</h6>
                    </div>
                    <div className="col-9">
                      {" "}
                      <p>
                        {get_seller_profile?.Userchaindata?.br_date &&
                          moment(
                            get_seller_profile?.Userchaindata?.br_date
                          ).format("MMM DD, yyyy hh:mm:ss A")}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="col-3">
                      <h6>ct date</h6>
                    </div>
                    <div className="col-9">
                      {" "}
                      <p>
                        {get_seller_profile?.Userchaindata?.ct_date &&
                          moment(
                            get_seller_profile?.Userchaindata?.ct_date
                          ).format("MMM DD, yyyy hh:mm:ss A")}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="col-3">
                      <h6>mq date</h6>
                    </div>
                    <div className="col-9">
                      {" "}
                      <p>
                        {get_seller_profile?.Userchaindata?.mq_date &&
                          moment(
                            get_seller_profile?.Userchaindata?.mq_date
                          ).format("MMM DD, yyyy hh:mm:ss A")}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="col-3">
                      <h6>duke date</h6>
                    </div>
                    <div className="col-9">
                      {" "}
                      <p>
                        {get_seller_profile?.Userchaindata?.duke_date &&
                          moment(
                            get_seller_profile?.Userchaindata?.duke_date
                          ).format("MMM DD, yyyy hh:mm:ss A")}
                      </p>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <h3>Rank Up down</h3>

              <div className="rankrole">
                <table>
                  <tr>
                   
                    <th>Rank name</th>
                    <th>Rank Up date</th>
                    <th>Rank Down date</th>
                    <th>Description</th>
                  </tr>

                  {get_seller_profile?.RankdownUpdata &&
                    get_seller_profile?.RankdownUpdata?.map((data, i) => {
                      return (
                        <tr>
                          <td>{data?.rankName}</td>
                          <td>
                            {data?.rankupDate &&
                              moment(data?.rankupDate).format(
                                "MMM DD, yyyy hh:mm:ss A"
                              )}
                          </td>
                          <td>
                            {data?.rankdownDate &&
                              moment(data?.rankdownDate).format(
                                "MMM DD, yyyy hh:mm:ss A"
                              )}
                          </td>
                          <td>{data?.description}</td>
                        </tr>
                      );
                    })}
                </table>
              </div> */}
            </div>
          </div>
        </div>
      </Modal>

      {/* as licence */}
      <Modal
        title="User Details"
        centered
        open={statusLicence}
        onOk={() => hendletoAssLicence(false)}
        onCancel={() => setstatusLicence(false)}
        width={1000}
      >
        <div>
          <div className="d-flex mb-5">
            <div className="col-8">
              <div className="d-flex">
                <div className="col-3">
                  <h6>license1 </h6>
                </div>
                <div className="col-9">
                  {" "}
                  <p> {get_seller_profile?.Userchaindata?.license1}</p>
                </div>
              </div>
              <div className="d-flex">
                <div className="col-3">
                  <h6>license2 </h6>
                </div>
                <div className="col-9">
                  {" "}
                  <p> {get_seller_profile?.Userchaindata?.license2}</p>
                </div>
              </div>
              <div className="d-flex">
                <div className="col-3">
                  <h6>license3 </h6>
                </div>
                <div className="col-9">
                  {" "}
                  <p> {get_seller_profile?.Userchaindata?.license3}</p>
                </div>
              </div>
              <div className="d-flex">
                <div className="col-3">
                  <h6>license4 </h6>
                </div>
                <div className="col-9">
                  {" "}
                  <p> {get_seller_profile?.Userchaindata?.license4}</p>
                </div>
              </div>
              <div className="d-flex">
                <div className="col-3">
                  <h6>br date</h6>
                </div>
                <div className="col-9">
                  {" "}
                  <p>
                    {get_seller_profile?.Userchaindata?.br_date &&
                      moment(get_seller_profile?.Userchaindata?.br_date).format(
                        "MMM DD, yyyy hh:mm:ss A"
                      )}
                  </p>
                </div>
              </div>
              <div className="d-flex">
                <div className="col-3">
                  <h6>ct date</h6>
                </div>
                <div className="col-9">
                  {" "}
                  <p>
                    {get_seller_profile?.Userchaindata?.ct_date &&
                      moment(get_seller_profile?.Userchaindata?.ct_date).format(
                        "MMM DD, yyyy hh:mm:ss A"
                      )}
                  </p>
                </div>
              </div>
              <div className="d-flex">
                <div className="col-3">
                  <h6>mq date</h6>
                </div>
                <div className="col-9">
                  {" "}
                  <p>
                    {get_seller_profile?.Userchaindata?.mq_date &&
                      moment(get_seller_profile?.Userchaindata?.mq_date).format(
                        "MMM DD, yyyy hh:mm:ss A"
                      )}
                  </p>
                </div>
              </div>
              <div className="d-flex">
                <div className="col-3">
                  <h6>duke date</h6>
                </div>
                <div className="col-9">
                  {" "}
                  <p>
                    {get_seller_profile?.Userchaindata?.duke_date &&
                      moment(
                        get_seller_profile?.Userchaindata?.duke_date
                      ).format("MMM DD, yyyy hh:mm:ss A")}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="">
            <div className="fv-row mb-7">
              <label className="fs-6 fw-semibold form-label mb-2">
                <span className="required">Select License</span>
                <i
                  className="fas fa-exclamation-circle ms-2 fs-7"
                  data-bs-toggle="popover"
                  data-bs-trigger="hover"
                  data-bs-html="true"
                  data-bs-content="Permission names is required to be unique."
                ></i>
              </label>

              <select
                value={lisshowcount}
                onChange={(e) => {
                  setlisscount(e.target.value);
                }}
                className="form-control bg-transparent"
              >
                <option value="">select license</option>
                <option value="1"> license 1</option>
                <option value="2"> license 2</option>
                <option value="3"> license 3</option>
                <option value="4"> license 4</option>
              </select>
            </div>
          </div>
          <div className="">
            <div className="fv-row mb-7">
              <label className="fs-6 fw-semibold form-label mb-2">
                <span className="required">Select User</span>
                <i
                  className="fas fa-exclamation-circle ms-2 fs-7"
                  data-bs-toggle="popover"
                  data-bs-trigger="hover"
                  data-bs-html="true"
                  data-bs-content="Permission names is required to be unique."
                ></i>
              </label>
              <AsyncPaginate
                value={userselete}
                loadOptions={loadOptionsPalyer}
                onChange={setuserselete}
                additional={{
                  page: 1,
                }}
              />
            </div>
          </div>
        </div>
      </Modal>

      {/* edit  */}
      <Modal
        title="Edit Profile"
        centered
        open={statusEdit}
        onOk={() => edituserProfileByadmin(false)}
        onCancel={() => setstatusEdit(false)}
        width={1400}
      >
        <div>
          <div className="d-flex mb-5">
            <div className="col-12">
              <div className="row">
                <div className="col-3">
                  <div className="p-5">
                    <label>Full Name </label>
                    <input
                      name="fullName"
                      value={userDetailsEdit?.fullName}
                      className="form-control"
                      onChange={(e) => {
                        hendleToprofileEdit(e);
                      }}
                    />
                  </div>
                </div>
                {/* <div className="col-3">
                  <div className="p-5">
                    <label>Full Name </label>
                    <input
                      name="fullName"
                      value={userDetailsEdit?.fullName}
                      className="form-control"
                    />
                  </div>
                </div> */}
                <div className="col-3">
                  <div className="p-5">
                    <label> </label>
                    {/* <input
                      name="fullName"
                      value={userDetailsEdit?.guardian}
                      className="form-control"
                    /> */}
                    <select
                      name="guardian"
                      className="form-control"
                      onChange={(e) => {
                        hendleToprofileEdit(e);
                      }}
                      value={userDetailsEdit?.guardian}
                    >
                      <option>Select </option>
                      <option value={"S/O"}>S/O</option>
                      <option value={"D/O"}>D/O</option>
                      <option value={"W/O"}>W/O</option>
                    </select>
                  </div>
                </div>
                <div className="col-3">
                  <div className="p-5">
                    <label>Father Name </label>
                    <input
                      name="father_name"
                      onChange={(e) => {
                        hendleToprofileEdit(e);
                      }}
                      value={userDetailsEdit?.father_name}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-3">
                  <div className="p-5">
                    <label>Email</label>
                    <input
                      name="email"
                      onChange={(e) => {
                        hendleToprofileEdit(e);
                      }}
                      value={userDetailsEdit?.email}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-3">
                  <div className="p-5">
                    <label>Phone</label>
                    <input
                      name="phone"
                      onChange={(e) => {
                        hendleToprofileEdit(e);
                      }}
                      value={userDetailsEdit?.phone}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-3">
                  <div className="p-5">
                    <label> Gender </label>
                    {/* <input
                      name="fullName"
                      value={userDetailsEdit?.guardian}
                      className="form-control"
                    /> */}
                    <select
                      className="form-control"
                      name="genger"
                      onChange={(e) => {
                        hendleToprofileEdit(e);
                      }}
                      value={userDetailsEdit?.gender}
                    >
                      <option>Select </option>
                      <option value={"male"}>Male</option>
                      <option value={"female"}>Female</option>
                    </select>
                  </div>
                </div>
                <div className="col-3">
                  <div className="p-5">
                    <label>City</label>
                    <input
                      name="city"
                      onChange={(e) => {
                        hendleToprofileEdit(e);
                      }}
                      value={userDetailsEdit?.city}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-3">
                  <div className="p-5">
                    <label>State</label>
                    <input
                      name="state"
                      onChange={(e) => {
                        hendleToprofileEdit(e);
                      }}
                      value={userDetailsEdit?.state}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-3">
                  <div className="p-5">
                    <label>Country</label>
                    <input
                      name="country"
                      onChange={(e) => {
                        hendleToprofileEdit(e);
                      }}
                      value={userDetailsEdit?.country}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-3">
                  <div className="p-5">
                    <label>Pic Code</label>
                    <input
                      name="zip"
                      onChange={(e) => {
                        hendleToprofileEdit(e);
                      }}
                      value={userDetailsEdit?.zip}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-3">
                  <div className="p-5">
                    <label> Marital Status</label>
                    {/* <input
                      name="fullName"
                      value={userDetailsEdit?.guardian}
                      className="form-control"
                    /> */}
                    <select
                      className="form-control"
                      name="marital"
                      onChange={(e) => {
                        hendleToprofileEdit(e);
                      }}
                      value={userDetailsEdit?.marital}
                    >
                      <option>Select </option>
                      <option value={"SINGLE"}>SINGLE</option>
                      <option value={"MARRIED"}>MARRIED</option>
                      <option value={"DIVORCED"}>DIVORCED</option>
                      <option value={"OTHER"}>OTHER</option>
                    </select>
                  </div>
                </div>
                <div className="col-3">
                  <div className="p-5">
                    <label>Profession</label>
                    <input
                      name="professional"
                      onChange={(e) => {
                        hendleToprofileEdit(e);
                      }}
                      value={userDetailsEdit?.professional}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-3">
                  <div className="p-5">
                    <label>Address-1</label>
                    <input
                      name="address1"
                      onChange={(e) => {
                        hendleToprofileEdit(e);
                      }}
                      value={userDetailsEdit?.address1}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-3">
                  <div className="p-5">
                    <label>Address-2</label>
                    <input
                      name="address2"
                      onChange={(e) => {
                        hendleToprofileEdit(e);
                      }}
                      value={userDetailsEdit?.address2}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* edit kyc  */}
      <Modal
        title="Edit Kyc"
        centered
        open={statusEditKyc}
        onOk={() => edituserKycByadmin(false)}
        onCancel={() => hendleToHideKyc(false)}
        width={1400}
      >
        <div>
          <div className="d-flex mb-5">
            <div className="col-12">
              <div className="row">
                <div className="col-3">
                  <div className="p-5">
                    <label>Acc No</label>
                    <input
                      name="acc_no"
                      onChange={(e) => {
                        hendleToprofileEditKyc(e);
                      }}
                      value={userKycEdit?.acc_no}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-3">
                  <div className="p-5">
                    <label>Ifsc</label>
                    <input
                      name="ifsc"
                      onChange={(e) => {
                        hendleToprofileEditKyc(e);
                      }}
                      value={userKycEdit?.ifsc}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-3">
                  <div className="p-5">
                    <label>Aadhar Card No</label>
                    <input
                      name="aadhar_card"
                      onChange={(e) => {
                        hendleToprofileEditKyc(e);
                      }}
                      value={userKycEdit?.aadhar_card}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-3">
                  <div className="p-5">
                    <label>Pan Card No</label>
                    <input
                      name="pan_card"
                      onChange={(e) => {
                        hendleToprofileEditKyc(e);
                      }}
                      value={userKycEdit?.pan_card}
                      className="form-control"
                    />
                  </div>
                </div>

                <div className="col-3">
                  <div className="p-5">
                    <label>Pan Card</label>
                    <ImageUploading
                      // multiple
                      value={images}
                      onChange={onChange}
                      maxNumber={maxNumber}
                      dataURLKey="data_url"
                      acceptType={["png", "jpeg", "jpg", "webp"]}
                      // acceptType={['jpg']}
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                      }) => (
                        <div className="upload__image-wrapper">
                          <button
                            style={isDragging ? { color: "red" } : {}}
                            onClick={onImageUpload}
                            {...dragProps}
                            className="btn btn-secondary"
                          >
                            Choose feature image
                          </button>
                          &nbsp;
                          <button
                            className="btn btn-secondary"
                            onClick={onImageRemoveAll}
                          >
                            Remove all images
                          </button>
                          {imageList.map((image, index) => (
                            <div key={index} className="image-item">
                              <img src={image.data_url} alt="" width="100" />
                              <div className="image-item__btn-wrapper">
                                <button
                                  className="btn btn-secondary"
                                  onClick={() => onImageUpdate(index)}
                                >
                                  Update
                                </button>
                                <button
                                  className="btn btn-secondary"
                                  onClick={() => onImageRemove(index)}
                                >
                                  Remove
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </ImageUploading>
                  </div>
                </div>
                <div className="col-3">
                  <div className="p-5">
                    <label>Aadhar Front</label>
                    <ImageUploading
                      // multiple
                      value={aadharFront}
                      onChange={onChangeaadharFront}
                      maxNumber={maxNumber}
                      dataURLKey="data_url"
                      acceptType={["png", "jpeg", "jpg", "webp"]}
                      // acceptType={['jpg']}
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                      }) => (
                        // write your building UI
                        <div className="upload__image-wrapper">
                          <button
                            style={isDragging ? { color: "red" } : {}}
                            onClick={onImageUpload}
                            {...dragProps}
                            className="btn btn-secondary"
                          >
                            Choose feature image
                          </button>
                          &nbsp;
                          <button
                            className="btn btn-secondary"
                            onClick={onImageRemoveAll}
                          >
                            Remove all images
                          </button>
                          {imageList.map((image, index) => (
                            <div key={index} className="image-item">
                              <img src={image.data_url} alt="" width="100" />
                              <div className="image-item__btn-wrapper">
                                <button
                                  className="btn btn-secondary"
                                  onClick={() => onImageUpdate(index)}
                                >
                                  Update
                                </button>
                                <button
                                  className="btn btn-secondary"
                                  onClick={() => onImageRemove(index)}
                                >
                                  Remove
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </ImageUploading>
                  </div>
                </div>
                <div className="col-3">
                  <div className="p-5">
                    <label>Aaddhar back</label>
                    <ImageUploading
                      // multiple
                      value={aadharBack}
                      onChange={onChangeaadharBack}
                      maxNumber={maxNumber}
                      dataURLKey="data_url"
                      acceptType={["png", "jpeg", "jpg", "webp"]}
                      // acceptType={['jpg']}
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                      }) => (
                        // write your building UI
                        <div className="upload__image-wrapper">
                          <button
                            style={isDragging ? { color: "red" } : {}}
                            onClick={onImageUpload}
                            {...dragProps}
                            className="btn btn-secondary"
                          >
                            Choose feature image
                          </button>
                          &nbsp;
                          <button
                            className="btn btn-secondary"
                            onClick={onImageRemoveAll}
                          >
                            Remove all images
                          </button>
                          {imageList.map((image, index) => (
                            <div key={index} className="image-item">
                              <img src={image.data_url} alt="" width="100" />
                              <div className="image-item__btn-wrapper">
                                <button
                                  className="btn btn-secondary"
                                  onClick={() => onImageUpdate(index)}
                                >
                                  Update
                                </button>
                                <button
                                  className="btn btn-secondary"
                                  onClick={() => onImageRemove(index)}
                                >
                                  Remove
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </ImageUploading>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <div className="flex">
        <div>
          <HeadingBack
            title={"Level Achive Income"}
            subHeading={`Total ${
              users_list?.totalDocs || 0
            }    Level Achive Income`}
          />
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          {/* <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Search..."
            // value={values?.TestCode}
          /> */}
          {/* <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetManager}
            onChange={setseleceteManger}
            additional={{
              page: 1,
            }}
          /> */}
        </Col>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          {/* <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            onChange={(e) => handleChangeStatus(e.target.value)}
            placeholder="Search..."
            // value={values?.TestCode}
          /> */}

          {/* <select
            className="form-control"
            onChange={(e) => handleChangeStatus(e.target.value)}
            value={statusfinal}
          >
     
            <option value={""}>All</option>
            <option value={"User"}>User</option>
            <option value={"Vendor"}>Vendor</option>
        
          </select> */}
          {/* <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetManager}
            onChange={setseleceteManger}
            additional={{
              page: 1,
            }}
          /> */}
        </Col>

        {/* <div className="flex1 flex justify-content-end align-items-center">
          <Tooltip title="Add">
            <span className="jop_btn" onClick={() => navigate("/user/add")}>
              Add
              <SvgIcon.Add width={40} height={40} className="cursor-pointer" />
            </span>
          </Tooltip>
        </div> */}
      </div>

      <div className="tableResponsive mt-10">
        <Table
          className={"table antd_table"}
          dataSource={listdata}
          columns={columns}
          pagination={{
            current: parseInt(page),
            pageSize: 10,
            onChange(current, pageSize) {
              hendlePahegi(current);
            },
            hideOnSinglePage: true,
            total: users_list?.totalDocs ? users_list?.totalDocs : 0,
          }}
        />
      </div>
    </div>
  );
}

export default LeavelAchiveIncome;
